/* eslint-disable max-len */
/* eslint-disable fp/no-let */
// import { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated-typescript';

import ImageZoomPopup from 'SourceComponent/ImageZoomPopup';
import {
    AMOUNT_OF_PLACEHOLDERS,
    MediaType,
    PRODUCT_GALLERY_POPUP_ID,
    THUMBNAIL_KEY,
} from 'SourceComponent/ProductGallery/ProductGallery.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductGalleryContainer as SourceProductGalleryContainer,
} from 'SourceComponent/ProductGallery/ProductGallery.container';
// import ProductGalleryComponent from './ProductGallery.component';
import {
    ProductGalleryContainerProps,
    SharedTransitionContainerRenderFnProps,
} from 'SourceComponent/ProductGallery/ProductGallery.type';
import SharedTransitionContainer from 'SourceComponent/SharedTransition/SharedTransition.unstated';
import { MediaGalleryEntry } from 'SourceQuery/ProductList.type';
import { ReactElement } from 'SourceType/Common.type';

import ProductGallery from './ProductGallery.component';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Inov8/Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceProductGalleryContainer {
    static defaultProps: Partial<ProductGalleryContainerProps> = {
        areDetailsLoaded: false,
        isZoomEnabled: false,
        isWithEmptySwitcher: false,
        showLoader: false,
    };

    getGalleryPictures(): MediaGalleryEntry[] {
        const {
            areDetailsLoaded,
            product: {
                media_gallery_entries: mediaGallery = [],
                [ THUMBNAIL_KEY ]: { url: thumbnailUrl = '' } = {},
                [ MediaType.IMAGE ]: { url: imageTypeUrl = '' } = {},
                name,
            },
            product,
            parameters,
        } = this.props;

        const url = imageTypeUrl || thumbnailUrl;
        let foundMatch = false;
        let ColorMediaGallery: any[] = [];

        if (parameters?.colours) {
            product.variants?.forEach((variant) => {
                if (!foundMatch) {
                    const variantColour = variant.attributes.colours.attribute_value;

                    if (parameters.colours === variantColour) {
                        foundMatch = true;
                        ColorMediaGallery = variant.media_gallery_entries;
                    }
                }
            });
        }

        if (foundMatch && ColorMediaGallery && ColorMediaGallery.length) {
            return ColorMediaGallery
                .filter(({ disabled }) => !disabled)
                .sort((a, b) => a.position - b.position);
        }

        if (mediaGallery.length) {
            return mediaGallery
                .filter(({ disabled }) => !disabled)
                .sort((a, b) => a.position - b.position);
        }

        if (!url) {
            return Array(AMOUNT_OF_PLACEHOLDERS + 1).fill({ media_type: 'placeholder' });
        }

        const placeholders = !areDetailsLoaded
            ? Array(AMOUNT_OF_PLACEHOLDERS).fill({ media_type: 'placeholder' }) : [];

        return [
            {
                thumbnail: { url },
                base: { url },
                id: THUMBNAIL_KEY,
                label: name,
                media_type: MediaType.IMAGE,
            },
            ...placeholders,
        ];
    }

    render(): ReactElement {
        const { isImageZoomPopupActive } = this.state;

        const activeImage = 0;

        return (
            <ImageZoomPopup
              isActive={ isImageZoomPopupActive }
              onClose={ this.handleImageZoomPopupClose }
              activeImageId={ activeImage }
              popupId={ PRODUCT_GALLERY_POPUP_ID }
            >
                <Subscribe to={ [SharedTransitionContainer] }>
                    { (props) => {
                        const {
                            registerSharedElementDestination,
                        } = props as unknown as SharedTransitionContainerRenderFnProps;

                        return (
                            <ProductGallery
                              registerSharedElementDestination={ registerSharedElementDestination }
                              { ...this.containerProps() }
                              { ...this.containerFunctions }
                            />
                        );
                    } }
                </Subscribe>
            </ImageZoomPopup>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
